import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

const dicts = {
  da: {
    tab_overview:     "Oversigt",
    tab_details:      "Detaljer",
    headline:         "Dit digitale aftryk",
    explanation:      "Vi indsamler information for at huske indstillinger, forbedre sikkerheden og analysere statistik. Vi sporer dig ikke systematisk på vores hjemmeside eller på tværs af andre hjemmesider og apps. Du kan altid ændre dit samtykke.",
    accept_all:       "Acceptér alle",
    accept_necessary: "Acceptér nødvendige",
    manage_cookies:   "Administrér cookies",
    accept_selected:  "Acceptér de valgte",
    change_later:     "Du kan altid ændre dine personlige indstillinger.",
    consent_groups: {
      "Nødvendige":      "Nødvendige",
      "Funktionelle":    "Funktionelle",
      "Statistik":       "Statistik",
      "Præferencer":     "Præferencer",
      "Brugeroplevelse": "Brugeroplevelse",
    },
    more_detail: "Her kan du finde en oversigt over hvilke cookies vi potentielt sætter.",
    more_detail_link: "Du kan se flere detaljer om vores cookies her",
  },
  en: {
    tab_overview:     "Overview",
    tab_details:      "Details",
    headline:         "Your Digital Footprint",
    explanation:      "We collect information to remember settings, improve security, and analyze statistics. We do not systematically track you on our website or across other websites and apps. You can always change your consent.",
    accept_all:       "Accept All",
    accept_necessary: "Accept necessary",
    manage_cookies:   "Manage Cookies",
    accept_selected:  "Accept Selected",
    change_later:     "You can always change your preferences later.",
    consent_groups: {
      "Nødvendige":      "Necessary",
      "Funktionelle":    "Functional",
      "Statistik":       "Statistics",
      "Præferencer":     "Preferences",
      "Brugeroplevelse": "User Experience",
    },
    more_detail: "Here you can see a list of the cookies that we may potentially set.",
    more_detail_link: "You can find in-depth info about our cookies here",
  }
};

Alpine.store('consent', {});

Alpine.data('cookieNotice', (config) => ({
    init() {

 
        const consent = window.CookieNotice.cookieExists(config.cookie_name) ? null : false;
        config.consent_groups.forEach(group => this.$store.consent[group.handle] = consent);
        window.CookieNotice.on('accepted', (group) => this.$store.consent[group] = true);
        window.CookieNotice.on('declined', (group) => this.$store.consent[group] = false);
        window.CookieNotice.boot(this.$root, config);
        this.$dispatch('cookie-notice-load');

                /*if (navigator.language.slice(0,2) != "da") {
          this.dict = dicts.en;
        }*/ 
        
        if(window.navigator.userAgent.indexOf('-v509') !== -1){
          this.hide();
       }
    },
    show() {
        window.CookieNotice.showWidget();
    },
    hide() {
        window.CookieNotice.hideWidget();
    },
    acceptAll() {
        window.CookieNotice.config.consent_groups.forEach((consentGroup) => {
            window.CookieNotice.widget.querySelector(`[name='group-${consentGroup.handle}']`).checked = true;
        });
        window.CookieNotice.savePreferences();
    },
  showList: false,
  tab: 'overview',
  dict: dicts.da,
  show() {
    window.CookieNotice.showWidget();
  },
  hide() {
    window.CookieNotice.hideWidget();
  },
  acceptAll() {
    window.CookieNotice.config.consent_groups.forEach((consentGroup) => {
      window.CookieNotice.widget.querySelector(`[name='group-${consentGroup.handle}']`).checked = true;
    });
    window.CookieNotice.savePreferences();
  },
  acceptNecessary() {
    window.CookieNotice.config.consent_groups.forEach((consentGroup) => {
      if(consentGroup.handle === 'necessary') {
        window.CookieNotice.widget.querySelector(`[name='group-${consentGroup.handle}']`).checked = true;
      } else {
        window.CookieNotice.widget.querySelector(`[name='group-${consentGroup.handle}']`).checked = false;
      }
    });
    window.CookieNotice.savePreferences();
  }
}));

Alpine.data('consentGate', ({ category }) => ({
    consent: null,
    init() {
        Alpine.effect(() => {
            this.consent = this.$store.consent[category];
            this.$nextTick(() => {
                if (this.consent === true) {
                    this.executeScripts(this.$root.innerHTML);
                }
            });
        });
    },
    executeScripts(html) {
        document.createRange()
            .createContextualFragment(html)
            .querySelectorAll('script')
            .forEach(script => {
                document.body.appendChild(script.cloneNode(true));
            });
    },
}));